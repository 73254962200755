import { useLocation } from '@remix-run/react'
import { useEffect } from 'react'

const ScrollToTop: React.FC = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        // Automatically scroll to the top of the page when the route changes
        // Locate the element with the ID "scrollable-container" in "apps/portal/app/routes/__portal.tsx"
        const scrollableContainer = document.getElementById('scrollable-container')
        if (scrollableContainer) {
            scrollableContainer.scrollTo(0, 0)
            console.log('Scrolled container to top:', scrollableContainer)
        }
    }, [pathname])

    return null
}

export default ScrollToTop
